
.anim-3d-wrapper {
    position: relative;
    left: 50px;
    top: -100px;
    z-index: 0;
  }

  .anim3d {
    position: absolute;
    transition: 0.8s;

  }
  
 .anim-3d-layer-bg {
    transform: rotate3d(1, -1, 0, -20deg);
    left: -380px;
    top: -250px;
    width: 1200px;
    height: 1200px;
    transform-origin: 50% 50% -40px;
    background: rgb(70,175,232);
    background: radial-gradient(circle, rgba(70,175,232,1) 0%, rgba(162,227,255,0.8) 35%, rgba(90,185,242,0.1) 60%, rgba(70,175,232,0) 70%);
    background-blend-mode: multiply;
    z-index: -10;
    pointer-events: none;
  }
  
  .anim-3d-layer-1 {
    transform: rotate3d(1, -1, 0, -20deg);
    transform-origin: 50% 50% -40px;
    width: 300px;
    height: 600px;
    border-radius: 30px;
    border: solid;
    border-width: 1px;
    background-color: rgba (255, 255, 255, 0);
    backdrop-filter: blur(6px);
    top: 0;
    z-index: 3;
    pointer-events: none;
    box-shadow: 20px 20px 100px rgba(255,255,255,0.9);
  }
  
  .anim-3d-layer-3 {
    transform: rotate3d(1, -1, 0, -20deg);
    width: 300px;
    height: 600px;
    border-radius: 30px;
    transform-origin: 50% 50% -20px;
    background-color: #000;
    top: 0;
    z-index: 3;
  }
  
  .anim-3d-layer-4 {
    overflow: hidden;
    transform: rotate3d(1, -1, 0, -20deg);
    animation: layer-4 10s;
    animation-delay: 4s;
    animation-iteration-count: infinite;
    top: 120px;
    left: 200px;
    width: 280px;
    height: 160px;
    border-radius: 30px;
    transform-origin: 50% 50% 300px;
    background: rgba(0,74,116,0.5);
    backdrop-filter: blur(6px);
    pointer-events: none;
    z-index: 8;
    transition: 2s;
  }
  @keyframes layer-4 {
    0% {left: 180px;} 
    50% {left: 220px;} 
    100% {left: 180px;} 
}
  
  .anim-3d-layer-5 {
    overflow: hidden;
    transform: rotate3d(1, -1, 0, -20deg);
    animation: layer-5 8s;
    animation-iteration-count: infinite;
    top: 580px;
    left: -60px;
    width: 260px;
    height: 200px;
    border-radius: 30px;
    transform-origin: 50% 50% 600px;
    background: rgba(0,74,116,0.5);
    backdrop-filter: blur(6px);
    pointer-events: none;
    z-index: 8;
    transition: 3s;
  }
    @keyframes layer-5 {
      0% {left: -60px;} 
      30% {left: -20px;} 
      100% {left: -60px;} 
  }
  
  .anim-3d-layer-6 {
    transform: rotate3d(1, -1, 0, -20deg);
    animation: layer-6 12s;
    animation-iteration-count: infinite;
    left: 20px;
    width: 500px;
    height: 200px;
    transform-origin: 50% 50% 200px;
    opacity: 25%;
    pointer-events: none;
    z-index: 7;
    transition: 4s;
  }
  @keyframes layer-6 {
    0% {top: 170px;} 
    50% {top: 200px;} 
    100% {top: 170px;} 
}
  
.anim-3d-layer-7 {
  overflow: hidden;
  transform: rotate3d(1, -1, 0, -20deg);
  animation: layer-7 15s;
  animation-iteration-count: infinite;
  top: 120px;
  width: 800px;
  height: 400px;
  border-radius: 20px;
  transform-origin: 50% 50% -400px;
  background: rgba(0,74,116,0.1);
  backdrop-filter: blur(6px);
  pointer-events: none;
  z-index: 1;
  transition: 5s;
}
  @keyframes layer-7 {
    0% {left: -300px;} 
    30% {left: -340px;} 
    100% {left: -300px;} 
}
  
.anim-3d-layer-8 {
  overflow: hidden;
  transform: rotate3d(1, -1, 0, -20deg);
  animation: layer-8 13s;
  animation-iteration-count: infinite;
  top: 100px;
  width: 700px;
  height: 400px;
  border-radius: 30px;
  transform-origin: 50% 50% -800px;
  background: rgba(0,74,116,0.1);
  backdrop-filter: blur(6px);
  pointer-events: none;
  z-index: 0.9;
  transition: 6s;
}
  @keyframes layer-8 {
    0% {left: -200px;} 
    30% {left: -120px;} 
    100% {left: -200px;} 
}
  
.anim-3d-layer-9 {
  overflow: hidden;
  transform: rotate3d(1, -1, 0, -20deg);
  animation: layer-9 12s;
  animation-iteration-count: infinite;
  top: -300px;
  width: 900px;
  height: 440px;
  border-radius: 30px;
  transform-origin: 50% 50% -1200px;
  background: rgba(0,74,116,0.1);
  backdrop-filter: blur(6px);
  pointer-events: none;
  z-index: 0.7;
  transition: 7s;
}
  @keyframes layer-9 {
    0% {left: -180px;} 
    30% {left: -230px;} 
    100% {left: -180px;} 
}
  
.anim-3d-layer-10 {
  overflow: hidden;
  transform: rotate3d(1, -1, 0, -20deg);
  animation: layer-10 11s;
  animation-iteration-count: infinite;
  top: -900px;
  width: 1200px;
  height: 700px;
  border-radius: 30px;
  transform-origin: 50% 50% -1600px;
  background: rgba(0,74,116,0.1);
  backdrop-filter: blur(6px);
  pointer-events: none;
  z-index: 0.6;
  transition: 8s;
}
  @keyframes layer-10 {
    0% {left: -590px;} 
    60% {left: -550px;} 
    100% {left: -590px;} 
}
  
  
  .anim-3d-layer-2 {
    overflow: hidden;
    transform: rotate3d(1, -1, 0, -20deg);
    background: rgb(84,255,244);
    background: linear-gradient(144deg, rgba(84,203,255,1) 0%, rgba(0,74,116,1) 100%);
    width: 300px;
    height: 600px;
    border-radius: 30px;
    z-index: 4;
  }
  
  .anim-3d-layer-2:hover {
    transform: rotate3d(0.2, -0.2, 0, -40deg);
    transition: 0.6s;
  }
  
  .anim-3d-layer-2:hover ~ .anim-3d-layer-1 {
    transform: rotate3d(0.2, -0.2, 0, -40deg);
    transition: 1.4s;
  }
  
  .anim-3d-layer-2:hover ~ .anim-3d-layer-3 {
    transform: rotate3d(0.2, -0.2, 0, -40deg);
    transition: 1.0s;
  }
  
  .anim-3d-layer-2:hover ~ .anim-3d-layer-4 {
    transform: rotate3d(0.2, -0.2, 0, -40deg);
    box-shadow: 20px 20px 60px rgba(255,255,255,0.6);
    background: rgba(84,185,244,0.5);
    transition: 1.8s;
  }

  .anim-3d-layer-2:hover ~ .anim-3d-layer-5 {
    transform: rotate3d(0.2, -0.2, 0, -40deg);
    box-shadow: 20px 20px 60px rgba(255,255,255,0.6);
    background: rgba(84,185,244,0.3);
    transition: 2.2s;
  }

  .anim-3d-layer-2:hover ~ .anim-3d-layer-6 {
    transform: rotate3d(0.2, -0.2, 0, -40deg);
    transition: 2.8s;
  }

  .anim-3d-layer-2:hover ~ .anim-3d-layer-7 {
    transform: rotate3d(0.2, -0.2, 0, -40deg);
    box-shadow: 20px 20px 60px rgba(255,255,255,0.6);
    background: rgba(84,185,244,0.2);
    transition: 3.6s;
  }

  .anim-3d-layer-2:hover ~ .anim-3d-layer-8 {
    transform: rotate3d(0.2, -0.2, 0, -40deg);
    box-shadow: 20px 20px 60px rgba(255,255,255,0.6);
    background: rgba(84,185,244,0.2);
    transition: 4.4s;
  }

  .anim-3d-layer-2:hover ~ .anim-3d-layer-9 {
    transform: rotate3d(0.2, -0.2, 0, -40deg);
    box-shadow: 20px 20px 60px rgba(255,255,255,0.6);
    background: rgba(84,185,244,0.2);
    transition: 5.4s;
  }

  .anim-3d-layer-2:hover ~ .anim-3d-layer-10 {
    transform: rotate3d(0.2, -0.2, 0, -40deg);
    box-shadow: 20px 20px 60px rgba(255,255,255,0.6);
    background: rgba(84,185,244,0.2);
    transition: 6.6s;
  }
  
  .anim-3d-layer-2:before {
    content: '';
    position: absolute;
    top: -200px;
    left: -300px;
    width: 80%;
    height: 300%;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 0px 60px 40px rgba(255, 255, 255, 0.6);
    filter: blur(1px);
    transform: skewX(-40deg);
    transition: 0.8s;
  }
  
  .anim-3d-layer-2:hover:before {
    left: 250px;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0px 0px 60px 40px rgba(255, 255, 255, 0.3);
  }
  
  .anim-3d-layer-4:before {
    content: '';
    position: absolute;
    top: -200px;
    left: -30px;
    width: 80%;
    height: 300%;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 0px 60px 40px rgba(255, 255, 255, 0.6);
    filter: blur(10px);
    transform: skewX(-40deg);
    transition: 2s;
  }
  
  .anim-3d-layer-2:hover ~ .anim-3d-layer-4:before {
    left: 520px;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0px 0px 60px 40px rgba(255, 255, 255, 0.3);
    transition: 1.8s;
  }
  
  .anim-3d-layer-5:before {
    content: '';
    position: absolute;
    top: -200px;
    left: -130px;
    width: 80%;
    height: 300%;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 0px 60px 40px rgba(255, 255, 255, 0.6);
    filter: blur(10px);
    transform: skewX(-40deg);
    transition: 3s;
  }
  
  .anim-3d-layer-2:hover ~ .anim-3d-layer-5:before {
    left: 500px;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0px 0px 60px 40px rgba(255, 255, 255, 0.3);
    transition: 2.2s;
  }
  