a {
  text-decoration: none;
  color: rgba(0, 74, 116, 1);
}

.App {
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
}

.sectionBody{
  width: 70%;
  margin: auto;
}
.sectionBodyP {
  transform: rotate3d(1, -1, 0, -20deg);
  transform-origin: 0% 0% 0px;
  position: relative;
  left: 14px;
}

h1 {
  transform: rotate3d(1, -1, 0, -20deg);
  transform-origin: 0% 0% 0px;
  position: relative;
  font-size: 42px;
  color: rgba(0, 74, 116, 1);
  background: -webkit-linear-gradient(140deg, rgba(84, 203, 255, 1) 0%, rgba(0, 74, 116, 1) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 10;
}

h2{
  transform: rotate3d(1, -1, 0, -20deg);
  transform-origin: 0% 0% 0px;
  position: relative;
  color: rgba(0, 74, 116, 1);
  background: -webkit-linear-gradient(140deg, rgba(84, 203, 255, 1) 0%, rgba(0, 74, 116, 1) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 10;
}

h3 {
  color: rgba(0, 74, 116, 1);
  background: -webkit-linear-gradient(140deg, rgba(84, 203, 255, 1) 0%, rgba(0, 74, 116, 1) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 10;
  margin: 0px;
  margin-bottom: 12px;
}

.sectionBody h2{
  background: -webkit-linear-gradient(140deg, rgba(84,203,255,1) 50%, rgba(0,74,116,1) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  left: 14px;
}

.sectionBody h4{
  transform: rotate3d(1, -1, 0, -20deg);
  transform-origin: 0% 0% 0px;
  position: relative;
  left: 14px;
}

.section {
  margin: 26px;
  padding-top: 100px;
  margin-bottom: 200px;
}

.section p{
  padding-bottom: 10px;
  max-width: 650px;
}

.section h4{
  font-weight: 400;
}

#section1 {
  padding-bottom: 70px;
  padding-top: 180px;

}

#section1 h2 {
  font-size: 5.8em;
  line-height: 100%;
  width: 520px;
  left: 11px;
  color:rgb(25, 118, 210);
  background: -webkit-linear-gradient(130deg, rgba(84,203,255,1) 0%, rgba(0,74,116,1));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 40px;
  margin-bottom: 4px;
  transition: 1s;
}
#section1 h4 {
  font-size: 1.5em;
  max-width: 300px;
  left: 10px;
  font-weight: 400;
  z-index: 5;
  transition: 1s;
}

.ModalBody {
  border-radius: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 80%;
  overflow: scroll;
  background-color: rgba(255,255,255,0.95);
  max-width: 1200px;
  border: 1px solid #000;
  padding: 20px;
}

.youTubeWrapper {
  padding-top: 20px;
  width: 90%;
  margin: auto;
  aspect-ratio: 16/9;
}

.cardBodyClick {
  scale: (1.0);
  cursor: pointer;
  overflow: hidden;
  transition: 0.6s;
  min-width: 200px;
  max-width: 400px;
  transform: rotate3d(1, -1, 0, -20deg);
  transform-origin: 50% 50% 0px;
}
.cardBodyClick:hover {
  transform: scale(1.05);
  transition: 0.4s;
  transform: rotate3d(0.2, -0.2, 0, -30deg);
  transform-origin: 50% 50% 0px;
}
.cardBodyClick:before {
  content: '';
  position: absolute;
  top: -200px;
  left: -300px;
  width: 80%;
  height: 300%;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 0px 60px 40px rgba(255, 255, 255, 0.3);
  transform: skewX(-40deg);
  transition: 0.6s;
}
.cardBodyClick:hover:before {
  left: 250px;
  transition: 0.4s;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 60px 40px rgba(255, 255, 255, 0.1);
}
.cardBodyNoClick {
  cursor: default;
}

.card-bg {
  width: 300px;
  overflow: hidden;
  background-color: rgba(255,255,255,0.5);
  border: solid;
  border-width: 1px;
  border-radius: 6px;
  margin: 6px;
  padding: 3px;
  border-color: rgba(0,80,200,0.1);
  transform: rotate3d(1, -1, 0, -20deg);
  transition: 0.2s;
}

.card-bg:hover {
  border-color: rgba(0, 74, 116, 1);
  transition: 0.2s;
}

.Navigation {
  width: 40%;
  max-width: 216px;
  margin-left: 20px;
  margin-top: 20px;
  overflow: hidden;
  transition: 0.5s;
  transition-property: width;
}

.mobileNav {
  position: fixed;
  margin: 10px;
  z-index: 20;
  border-width: 1px;
  border: solid;
  border-radius: 6px;
  border-color: rgba(0,80,200,0.1);
  background-color: rgba(0,80,200,0.1);
}

.mobileHeader {
  position: fixed;
  transition: 1s;
  left: -110px;
  background: rgba(255,255,255,0.7);
  backdrop-filter: blur(6px);
  border: solid;
  border-width: 1px;
  border-color: rgba(0,0,0,0.05);
  width: 92px;
  height: 63px;
  border-radius: 0 0 12px 0;
  z-index: 50;
}

.mobileNavFixed {
  padding: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 6px;
  width: 100%;
  transition: 1s;
  margin-left: 10%;
  overflow: hidden;
  pointer-events: none;
  z-index: 50;
}

#positioned-menu{
  height: 1000px;
  text-align: center;
}

.mobileMenuInner{
  width: 270px;
  height: 100%;
  padding-bottom: 10px;
  text-align: center;
  background: rgba(255,255,255,0.7);
  backdrop-filter: blur(6px);
}

.navWrapper {
  position: fixed;
  width: 20%;
  max-width: 214px;
  border-radius: 6px;
  text-align: center;
  transition: 1s;
  transition-property: opacity;
  opacity: 100%;
}

.nav-icons {
  transform: rotate3d(1, -1, 0, -20deg);
  transform-origin: 0% 0% 0px;
  position: relative;
  color: rgba(0, 74, 116, 1);
  background: -webkit-linear-gradient(140deg, rgba(84, 203, 255, 1) 0%, rgba(0, 74, 116, 1) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 10;
}

.nav-footer {
  position: fixed;
  bottom: 20px;
  width: 20%;
  max-width: 212px;
  border-radius: 6px;
  text-align: center;
  line-height: 100%;
  font-size: 0.7em;
  transition: 1s;
  transition-property: opacity;
  opacity: 100%;
  transform: rotate3d(1, -1, 0, -20deg);
  transform-origin: 0% 0% 0px;
}
.nav-footer h4 {
  margin-block-start: 0;
  margin-block-end: 4px;
}
.nav-footer p {
  margin-block-start: 0;
  margin-block-end: 10px;
  background: -webkit-linear-gradient(140deg, rgba(84, 203, 255, 1) 0%, rgba(0, 74, 116, 1) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  line-height: normal;
}

.navFooterMobile {
  padding-top: 20px;
  padding-bottom: 16px;
  border-radius: 6px;
  text-align: center;
  line-height: 100%;
  transition: 1s;transform: rotate3d(1, -1, 0, -20deg);
  transform-origin: 0% 0% 0px;
}
.navFooterMobile h4 {
  margin-block-start: 0;
  margin-block-end: 4px;
}
.navFooterMobile p {
  margin-block-start: 0;
  margin-block-end: 10px;
  background: -webkit-linear-gradient(140deg, rgba(84, 203, 255, 1) 0%, rgba(0, 74, 116, 1) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  line-height: normal;
}

.button-group {
  border-radius: 6px;
  transition: 1s;
  transform: rotate3d(1, -1, 0, -20deg);
  transform-origin: 0% 0% 0px;
  color: rgba(0, 74, 116, 1);
}

.nameTitle {
  line-height: 100%;
}

.profilePhoto {
  height: 100px;
  width: 100px;
  margin: auto;
  margin-top: 20px;
  border: solid;
  border-width: 5px;
  background-clip: border-box;
  border-color: rgba(0,74,116,0.7);
  border-radius: 100%;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.4);
}
.profilePhoto img{
  transform: scale(100%);
  border-radius: 100%;
}

.wrapper-3d{
  transition: 1s;
}


@media only screen and (max-width: 1400px) {
  .wrapper-3d{
    transform: scale(0.75);
    transition: 1s;
  }
  #section1 h2 {
    font-size: 3.2em;
    transition: 1s;
    width: 300px;
  }
  #section1 h4 {
    font-size: 1.2em;
    transition: 1s;
  }
}


@media only screen and (max-width: 800px) {

  .Navigation {
    width: 0px;
    transition: 0.5s;
    transition-delay: 0.5s;
    transition-property: width;
    pointer-events: none;
  }

  .navWrapperClose {
    transition: 1s;
    transition-property: opacity;
    opacity: 0;
  }

  .navFooterClose {
    transition: 1s;
    transition-property: opacity;
    opacity: 0;
  }

  .sectionBody{
    width: 100%;
    margin-left: 0px;
    border-left-width: 0px;
  }

  .wrapper-3d{
    transform: scale(0.5);
    transition: 1s;
  }

  .section {
    padding-left: 6px;
    padding-right: 6px;
    margin-bottom: 40px;
    z-index: 10;
  };

  .mobileNavFixed {
    width: 80%;
    transition: 1s;
    overflow: visible;
    pointer-events: auto;
  }

  .mobileHeader {
    position: fixed;
    left: 0px;
    transition: 1s;
  }
}

/*scrollbar styles*/
/* width */
::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}

/* hover */
::-webkit-scrollbar:hover {
  width: 8px;
  border-radius: 8px;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  margin-top: 6px;
  margin-bottom: 6px;
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(84,
    203,
    255,
    1);
  background: linear-gradient(128deg,
      rgba(84,
        203,
        255,
        1) 0%,
      rgba(0,
        74,
        116,
        1) 49%);
  border-radius: 6px;
  box-shadow: inset 0 0 2px 0px #4760cd;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(84,
    203,
    255,
    1);
  background: linear-gradient(128deg,
      rgba(84,
        203,
        255,
        1) 0%,
      rgba(0,
        74,
        116,
        1) 49%);
  width: 10px;
}

/*scrollbar styles*/