body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(240,240,240);
  background-image: linear-gradient(
  115deg,
  hsl(206deg 100% 89%) 0%,
  hsl(211deg 73% 89%) 1%,
  hsl(216deg 44% 89%) 3%,
  hsl(223deg 20% 88%) 6%,
  hsl(228deg 8% 88%) 11%,
  hsl(228deg 8% 92%) 16%,
  hsl(228deg 8% 95%) 24%,
  hsl(228deg 8% 99%) 33%,
  hsl(344deg 0% 98%) 46%,
  hsl(344deg 0% 96%) 61%,
  hsl(344deg 0% 94%) 79%,
  hsl(0deg 0% 92%) 100%
);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

